export const AUTH_ICON_MAP = {
    OWN: 'home',
    EDIT: 'pencil',
    VIEW: 'eye',
}

export const AUTH_DESCRIPTION_MAP = {
    OWN: 'Own',
    EDIT: 'Can Edit',
    VIEW: 'Can View',
}
